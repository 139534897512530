export default defineNuxtRouteMiddleware(async (to, from) => {
  // Add check to run only on server side
  if (process.client) return;

  const nuxtApp = useNuxtApp();
  const locale = nuxtApp?.$i18n?.locale.value ?? "ar";

  const headersToPass = {
    ...nuxtApp.ssrContext?.event.node.req.headers,
    "App-Locale": locale,
  };

  if (!to.params.seasonNumber) {
    const seriesInfo = await $fetch(
      `${process.env.BASE_URL}/api/biz/video/aggregate/detail?externalSeriesId=${to.params.seriesId}&type=Series&contentType=Series`,
      {
        headers: headersToPass,
      }
    );

    const showSeasons = seriesInfo?.response?.tvShowSeasons;

    if (showSeasons === undefined || showSeasons?.length === 0) {
      throw createError({
        statusCode: 404,
      });
    } else {
      return navigateTo(
        {
          path: nuxtApp.$localePath(
            `/shows/${to.params.seriesId}/${to.params.slug}/season-${showSeasons?.[0]?.seasonNumber}`
          ),
          replace: true,
        },
        {
          redirectCode: 301,
        }
      );
    }
  }
});
